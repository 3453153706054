<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterRequests"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openRequest"
            fixed-header
        >
            <template v-slot:top>
                <div class="d-flex justify-center">
                    <v-row
                        no-gutter
                        :style="{ 'background-color': '#eeeeee' }"
                        class="d-flex justify-center py-4 mx-0"
                    >
                        <div>
                            <v-col cols="6" class="d-flex" v-if="!projectId">
                                <v-btn-toggle
                                    mandatory
                                    borderless
                                    :style="{ height: '36px' }"
                                >
                                    <v-btn
                                        @change="setPMPurchases(false)"
                                        :style="{
                                            'font-size': '12px',
                                            height: '36px',
                                        }"
                                        class="px-0 py-1"
                                    >
                                        {{
                                            readAllPurchases
                                                ? 'All Purchases'
                                                : 'Created by Me'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        @change="setPMPurchases(true)"
                                        :style="{
                                            'font-size': '12px',
                                            height: '36px',
                                        }"
                                        class="px-xs-1 px-sm-1 px-md-3 px-lg-3 px-xl-3 py-1"
                                    >
                                        Project Manager
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>

                            <v-col cols="6" class="d-flex" v-if="projectId">
                                <v-btn-toggle
                                    mandatory
                                    borderless
                                    :style="{ height: '36px' }"
                                >
                                    <v-btn
                                        @change="showLivePurchases(true)"
                                        :style="{
                                            'font-size': '12px',
                                            height: '36px',
                                        }"
                                        class="px-3 py-1"
                                    >
                                        Live Purchases
                                    </v-btn>
                                    <v-btn
                                        @change="showLivePurchases(false)"
                                        :style="{
                                            'font-size': '12px',
                                            height: '36px',
                                        }"
                                        class="px-3 py-1"
                                    >
                                        Closed
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </div>
                        <div>
                            <v-btn
                                small
                                icon
                                @click="openPurchaseSchedule"
                                color="primary"
                                class="mt-4"
                            >
                                <v-icon>
                                    mdi-calendar
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-spacer v-if="!$vuetify.breakpoint.smAndDown" />
                        <div>
                            <v-col cols="12" class="d-flex" align-self="center">
                                <v-btn
                                    v-if="informAcces"
                                    rounded
                                    color="primary"
                                    @click="openGenerateXLS"
                                >
                                    <v-icon>mdi-file-excel</v-icon>
                                    INFORM
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-2"
                                    @click="openNewRequest"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    NEW
                                </v-btn>
                            </v-col>
                        </div>
                    </v-row>
                </div>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="idToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.items`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="itemsToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.type`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="typeToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.date`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="dateToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="usersToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.dueDate`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="dueDateToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.notes`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="notesToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.date`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            formatDate(
                                item.createdOn.seconds
                                    ? item.createdOn.seconds
                                    : item.createdOn._seconds
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.dueDate`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.dueDate }}</p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="my-0">
                    <div>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ item.createdBy.name }}
                                </p>
                            </template>
                            <span>Creator</span>
                        </v-tooltip>
                    </div>
                    <div v-if="item.projectManager">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ item.projectManager.name }}
                                </p>
                            </template>
                            <span>Project Manager</span>
                        </v-tooltip>
                    </div>
                    <div v-if="item.buyer">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ getUser(item.buyer) }}
                                </p>
                            </template>
                            <span>Buyer</span>
                        </v-tooltip>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.items`]="{ item }">
                <div>
                    <p
                        class="my-0"
                        v-for="(element, index) of item.items"
                        :key="index"
                    >
                        {{ element.description }} ({{ element.quantity }})
                        {{
                            element.purchaseOrder
                                ? '(' + element.purchaseOrder.orderNumber + ')'
                                : ''
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.totalPrice`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ formatNumber(calculateTotal(item)) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.project.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip :color="statusColors[item.status].color" dark>
                        <span
                            class="mt-0 text-body-1 text-capitalize"
                            :style="{ 'text-align': 'center !important' }"
                            >{{ statusColors[item.status].text }}</span
                        >
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn icon v-bind="attrs" v-on="on" small class="mx-1">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="
                                item.status == 'inProgress' ||
                                    item.status == 'rejected' ||
                                    item.status == 'closed' ||
                                    readAllPurchases
                            "
                            @click.stop="editRequest(item)"
                        >
                            <v-list-item-title>
                                {{ item.status == 'closed' ? 'View' : 'Edit' }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="
                                item.status == 'inTransit' &&
                                    item.type == 'service' &&
                                    item.createdBy.id == userId
                            "
                            @click.stop="editRequest(item)"
                        >
                            <v-list-item-title>
                                Send to Received
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="
                                item.status == 'inProgress' ||
                                    item.status == 'rejected'
                            "
                            @click.stop="openDeleteRequest(item)"
                        >
                            <v-list-item-title>
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openTimeLineDialog(item)">
                            <v-list-item-title>
                                Timeline
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--Request Form-->
        <v-dialog persistent fullscreen v-model="openForm">
            <PurchaseRequest
                v-if="openForm"
                :request="selectedRequest"
                :originalRequest="originalRequest"
                :createForm="createForm"
                :projectId="projectId"
                @closeDialog="closeRequestForm"
                @addRequest="addRequest"
                @replaceRequest="replaceRequest"
            />
        </v-dialog>
        <!--Delete Request-->
        <v-dialog
            v-model="deleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWarning">
                <v-card-title class="text-h5">Delete Request</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this request?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteRequest">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteRequest"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- INFORM -->
        <v-dialog
            v-model="openInform"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading" v-if="openInform">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeGenerateXLS"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        INFORM
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    <v-form>
                        <v-row no-gutters>
                            <v-col cols="12" class="pt-4">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-combobox
                                            class="py-0 my-0"
                                            v-model="dateRangeText"
                                            color="primary"
                                            label="Date range"
                                            readonly
                                            flat
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"
                                            prepend-icon="mdi-calendar-blank-outline"
                                        ></v-combobox>
                                    </template>
                                    <v-date-picker
                                        v-model="dates"
                                        range
                                        scrollable
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="generateXLS"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        GENERATE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Time Line-->
        <v-dialog v-model="openTimeLine" max-width="500">
            <TimeLinePurchase
                v-if="openTimeLine"
                :request="timeLineRequest"
                :users="users"
                @closeDialog="closeTimeLine"
            />
        </v-dialog>
        <!--Purchase Schedule-->
        <v-dialog
            :retain-focus="false"
            persistent
            fullscreen
            v-model="openSchedule"
        >
            <PurchaseSchedule
                v-if="openSchedule"
                :purchases="processed"
                :projectId="projectId"
                :users="users"
                @close="closePurchaseSchedule"
                @replace="replaceRequest"
            />
        </v-dialog>
    </div>
</template>

<script>
import PurchaseRequest from '@/components/Purchasing/PurchaseRequest.vue'
import TimeLinePurchase from '@/components/Purchasing/TimeLinePurchase.vue'
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import moment from 'moment'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    name: 'PurchasingRequest',
    components: {
        PurchaseRequest,
        TimeLinePurchase,
        PurchaseSchedule: () =>
            import('@/components/Purchasing/PurchaseSchedule.vue'),
    },
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    data: () => ({
        openSchedule: false,
        loading: false,
        activateAlert: false,
        alertMessage: undefined,
        headers: [
            {
                text: 'ID',
                value: 'number',
                sortable: false,
                align: 'center',
                width: '100px',
            },
            {
                text: 'TYPE',
                value: 'type',
                sortable: false,
                align: 'center',
            },
            {
                text: 'PROJECT',
                value: 'project',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEMS',
                value: 'items',
                sortable: false,
                align: 'left',
            },
            {
                text: 'TOTAL PRICE',
                value: 'totalPrice',
                sortable: false,
                align: 'center',
            },
            {
                text: 'REQ DATE',
                value: 'date',
                sortable: false,
                align: 'center',
            },
            {
                text: 'DUE DATE',
                value: 'dueDate',
                sortable: false,
                align: 'center',
            },

            {
                text: 'STATUS',
                value: 'status',
                sortable: false,
                align: 'center',
                width: '100px',
            },
            {
                text: 'NOTES',
                value: 'notes',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        requests: [],
        statusColors: Object.freeze({
            inProgress: {
                text: 'inProgress',
                color: 'primary',
            },
            onHold: {
                text: 'onHold',
                color: 'green',
            },
            pricing: {
                text: 'pricing',
                color: '#3293e6',
            },
            preApproval: {
                text: 'preApproval',
                color: '#FF9800',
            },
            approval: {
                text: 'approval',
                color: '#27BC4F',
            },
            purchasing: {
                text: 'purchasing',
                color: 'primary',
            },
            inTransit: {
                text: 'inTransit',
                color: '#ff7800',
            },
            received: {
                text: 'received',
                color: '#00a135',
            },
            closed: {
                text: 'closed',
                color: '',
            },
            rejected: {
                text: 'rejected',
                color: 'red',
            },
        }),
        statusToFilter: undefined,
        itemsToFilter: undefined,
        idToFilter: undefined,
        typeToFilter: undefined,
        dateToFilter: undefined,
        usersToFilter: undefined,
        dueDateToFilter: undefined,
        projectToFilter: undefined,
        notesToFilter: undefined,
        createForm: false,
        openForm: false,
        selectedRequest: {},
        originalRequest: {},
        listener: null,
        companyId: JSON.parse(localStorage.getItem('company')),
        userId: JSON.parse(localStorage.getItem('userId')),
        deleteWarning: false,
        users: [],
        readAllPurchases: false,
        user: {},
        openInform: false,
        menu: false,
        dates: [],
        startDate: '',
        endDate: '',
        informAcces: false,
        openTimeLine: false,
    }),
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        filterRequests() {
            let conditions = []

            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }

            if (this.itemsToFilter) {
                conditions.push(this.filterForItems)
            }

            if (this.idToFilter) {
                conditions.push(this.filterForId)
            }

            if (this.typeToFilter) {
                conditions.push(this.filterForType)
            }

            if (this.dateToFilter) {
                conditions.push(this.filterForDate)
            }

            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }

            if (this.dueDateToFilter) {
                conditions.push(this.filterForDueDate)
            }

            if (this.projectToFilter) {
                conditions.push(this.filterForProject)
            }

            if (this.notesToFilter) {
                conditions.push(this.filterForNotes)
            }

            if (conditions.length > 0) {
                return this.requests.filter(request => {
                    return conditions.every(condition => {
                        return condition(request)
                    })
                })
            }
            this.sort()
            return this.requests
        },
        processed() {
            return this.requests.filter(
                request =>
                    request.status == 'inTransit' ||
                    request.status == 'received'
            )
        },
    },
    async created() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.loading = true
            document.body.classList.add('no-scroll')
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.informAcces = this.user.permissions.includes('assignBuyer')
            if (this.projectId) {
                this.requests = await API.getAllPurchases({
                    projectId: this.projectId,
                })
                Object.values(this.requests).forEach(request => {
                    request.dueDate = this.formatDate(request.dueDate._seconds)

                    Object.values(request.items).forEach(item => {
                        request.itemsLabel = request.itemsLabel
                            ? request.itemsLabel +
                              `${item.description} ${
                                  item.purchaseOrder &&
                                  item.purchaseOrder.orderNumber
                                      ? '(' +
                                        item.purchaseOrder.orderNumber +
                                        ')'
                                      : ''
                              }`
                            : `${item.description} ${
                                  item.purchaseOrder &&
                                  item.purchaseOrder.orderNumber
                                      ? '(' +
                                        item.purchaseOrder.orderNumber +
                                        ')'
                                      : ''
                              }`
                    })
                })
                this.headers.splice(5, 0, {
                    text: 'USERS',
                    value: 'createdBy',
                    sortable: false,
                    align: 'left',
                })
                return null
            }
            let PurchasesQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')

            if (!this.user.permissions.includes('allPurchases')) {
                PurchasesQuery = PurchasesQuery.where(
                    'createdBy.id',
                    '==',
                    this.userId
                )
            } else {
                this.readAllPurchases = true
                this.headers.splice(5, 0, {
                    text: 'USERS',
                    value: 'createdBy',
                    sortable: false,
                    align: 'left',
                })
            }
            await this.getPurchases(PurchasesQuery)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closePurchaseSchedule() {
            this.openSchedule = false
        },
        openPurchaseSchedule() {
            this.openSchedule = true
        },
        async getPurchases(PurchasesQuery) {
            try {
                this.loading = true
                this.listener = await PurchasesQuery.onSnapshot(
                    async docSnapshot => {
                        docSnapshot.docChanges().forEach(async change => {
                            let request = Object.assign(change.doc.data(), {
                                id: change.doc.id,
                            })
                            if (request.items) {
                                request.totalPrice = 0
                                request.items.forEach(item => {
                                    if (item.quotes && item.quotes.length > 0) {
                                        let quote = item.quotes.find(
                                            quote => quote.able
                                        )
                                        request.totalPrice +=
                                            item.quantity *
                                            (quote ? quote.price : 0)
                                    }
                                    request.itemsLabel = request.itemsLabel
                                        ? request.itemsLabel +
                                          `${item.description} ${
                                              item.purchaseOrder
                                                  ? '(' +
                                                    item.purchaseOrder
                                                        .orderNumber +
                                                    ')'
                                                  : ''
                                          }`
                                        : `${item.description} ${
                                              item.purchaseOrder
                                                  ? '(' +
                                                    item.purchaseOrder
                                                        .orderNumber +
                                                    ')'
                                                  : ''
                                          }`
                                })
                            }
                            if (change.type === 'added') {
                                request.dueDate = this.formatDate(
                                    request.dueDate.seconds
                                )
                                this.requests.push(request)
                            }
                            if (change.type === 'modified') {
                                request.dueDate = this.formatDate(
                                    request.dueDate.seconds
                                )
                                const index = this.requests.findIndex(
                                    r => r.id == request.id
                                )
                                if (index >= 0) {
                                    this.requests.splice(index, 1, request)
                                }
                            }
                            if (change.type === 'removed') {
                                const index = this.requests.findIndex(
                                    r => r.id == request.id
                                )
                                if (index >= 0) {
                                    this.requests.splice(index, 1)
                                }
                            }
                        })
                    }
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getUser(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return null
        },
        sort() {
            this.requests.sort(
                (a, b) => b.createdOn.seconds - a.createdOn.seconds
            )
        },
        formatNumber(number) {
            return new Intl.NumberFormat('de-DE').format(
                Math.ceil(number != undefined ? number : 0)
            )
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm:ss')
        },
        formatDate2(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        replaceRequest(request) {
            if (this.projectId) {
                const index = this.requests.findIndex(r => r.id == request.id)
                if (index >= 0) {
                    const totalPrice = request.items?.reduce(
                        (accumulator, item) => {
                            let price = 0
                            if (item.quotes) {
                                for (const quote of item.quotes) {
                                    if (quote.able) {
                                        price += quote.price * item.quantity
                                    }
                                }
                            }
                            return accumulator + price
                        },
                        0
                    )
                    this.requests.splice(index, 1, {
                        ...this.requests[index],
                        ...request,
                        ...(totalPrice != undefined ? { totalPrice } : {}),
                    })
                }
            }
        },
        addRequest() {
            this.openForm = false
            this.alertMessage = 'Purchase was successfully Requested'
            this.activateAlert = true
        },
        filterForStatus(item) {
            return (
                item.status &&
                item.status
                    .toLowerCase()
                    .includes(
                        this.statusToFilter.replaceAll(' ', '').toLowerCase()
                    )
            )
        },
        filterForItems(item) {
            return (
                item.itemsLabel &&
                item.itemsLabel
                    .toLowerCase()
                    .includes(this.itemsToFilter.toLowerCase())
            )
        },
        filterForId(item) {
            return item.number.toString().includes(this.idToFilter)
        },
        filterForType(item) {
            return item.type
                .toLowerCase()
                .includes(this.typeToFilter.toLowerCase())
        },
        filterForDate(item) {
            const date = this.formatDate(
                item.createdOn.seconds
                    ? item.createdOn.seconds
                    : item.createdOn._seconds
            )
            return date.includes(this.dateToFilter)
        },
        filterForDueDate(item) {
            return item.dueDate.includes(this.dueDateToFilter)
        },
        filterForNotes(item) {
            return (
                item.notes &&
                item.notes
                    .toLowerCase()
                    .includes(this.notesToFilter.toLowerCase())
            )
        },
        filterForProject(item) {
            return item.project.name
                .toLowerCase()
                .includes(this.projectToFilter.toLowerCase())
        },
        filterForUsers(item) {
            return (
                (item.createdBy.name &&
                    item.createdBy.name
                        .toLowerCase()
                        .includes(this.usersToFilter.toLowerCase())) ||
                (item.projectManager.name &&
                    item.projectManager.name
                        .toLowerCase()
                        .includes(this.usersToFilter.toLowerCase())) ||
                (item.buyer &&
                this.users.find(user => user.id == item.buyer) != undefined
                    ? this.users
                          .find(user => user.id == item.buyer)
                          .name.toLowerCase()
                          .includes(this.usersToFilter.toLowerCase())
                    : false)
            )
        },
        async deleteRequest() {
            try {
                this.loading = true
                await API.deletePurchaseRequest(this.selectedRequest.id)
                this.closeDeleteRequest()
                const index = this.requests.findIndex(
                    r => r.id == this.selectedRequest.id
                )
                if (index >= 0) {
                    this.requests.splice(index, 1)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteRequest() {
            this.selectedRequest = {}
            this.deleteWarning = false
        },
        openDeleteRequest(request) {
            this.selectedRequest = _.cloneDeep(request)
            this.deleteWarning = true
        },
        editRequest(request) {
            this.createForm = false
            this.selectedRequest = _.cloneDeep(request)
            this.originalRequest = _.cloneDeep(request)
            this.openForm = true
        },
        openNewRequest() {
            this.createForm = true
            this.openForm = true
        },
        closeRequestForm() {
            this.openForm = false
            this.selectedRequest = {}
            this.originalRequest = {}
        },
        openRequest(request) {
            this.createForm = false
            this.selectedRequest = { ...request }
            this.originalRequest = { ...request }
            this.openForm = true
        },
        calculateTotal(request) {
            let calculatedTotal = 0
            request.items = request.items ? request.items : []
            request.items.forEach(i => {
                i.quotes = i.quotes ? i.quotes : []
                i.quotes.forEach(q => {
                    if (q.able) {
                        calculatedTotal += i.quantity * q.price
                    }
                })
            })
            return calculatedTotal
        },
        openTimeLineDialog(request) {
            this.timeLineRequest = _.cloneDeep(request)
            this.openTimeLine = true
        },
        closeTimeLine() {
            this.openTimeLine = false
            this.timeLineRequest = {}
        },
        async setPMPurchases(option) {
            this.listener()
            this.listener = null
            this.requests = []
            let PurchasesQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')

            if (option) {
                PurchasesQuery = PurchasesQuery.where(
                    'projectManager.id',
                    '==',
                    this.userId
                )
            } else {
                if (!this.user.permissions.includes('allPurchases')) {
                    PurchasesQuery = PurchasesQuery.where(
                        'createdBy.id',
                        '==',
                        this.userId
                    )
                }
            }
            await this.getPurchases(PurchasesQuery)
        },
        async showLivePurchases(option) {
            try {
                this.loading = true
                const query = option
                    ? {
                          projectId: this.projectId,
                      }
                    : {
                          projectId: this.projectId,
                          status: ['closed'],
                      }

                this.requests = await API.getPurchasesClosed(query)
                this.requests.forEach(request => {
                    request.dueDate = this.formatDate(request.dueDate._seconds)
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeGenerateXLS() {
            this.openInform = false
        },
        openGenerateXLS() {
            this.openInform = true
        },
        setDate() {
            if (this.dates[0] > this.dates[1]) {
                this.startDate = this.dates[1]
                this.endDate = this.dates[0]
            } else {
                this.startDate = this.dates[0]
                this.endDate = this.dates[1]
            }
            this.menu = false
        },
        async generateXLS() {
            try {
                this.loading = true
                this.setDate()
                const projects = await API.getLiteProjects({ all: true })
                const informPurchases = await API.getInformPurchases({
                    startDate: this.startDate,
                    endDate: this.endDate,
                })
                const reportData = []
                informPurchases.sort((a, b) => a.number - b.number)
                for (const purchase of informPurchases) {
                    let row = {}
                    delete purchase.id
                    delete purchase.timestamp
                    delete purchase.first_created

                    const {
                        approvedBy,
                        approvedDate,
                        buyer,
                        createdBy,
                        createdOn,
                        dueDate,
                        deliveryLocation,
                        items,
                        number,
                        project,
                        projectManager,
                        status,
                        type,
                        inTransitBy,
                        preApprovedBy,
                        purchasedBy,
                        receivedBy,
                        closedBy,
                        inProgressBy,
                        inProgressDate,
                        pricingBy,
                        pricingDate,
                        purchasedDate,
                        updatedOn,
                        preApprovedDate,
                        purchaseOrders,
                        quotes,
                        attachments,
                        projectId,
                        receivedDate,
                        inTransitDate,
                        closedDate,
                        assignBy,
                        assignDate,
                        rejectedDate,
                        ...params
                    } = purchase

                    let purchaseOrdersString = ''
                    if (purchaseOrders && purchaseOrders.length > 0) {
                        for (const index in purchaseOrders) {
                            purchaseOrdersString += purchaseOrders[index]
                            if (index < purchaseOrders.length - 1) {
                                purchaseOrdersString += ', '
                            }
                        }
                    }

                    let quotesString = ''
                    if (quotes && quotes.length > 0) {
                        for (const index in quotes) {
                            quotesString += quotes[index]
                            if (index < quotes.length - 1) {
                                quotesString += ', '
                            }
                        }
                    }

                    let attachmentsString = ''
                    if (attachments && attachments.length > 0) {
                        for (const index in attachments) {
                            attachmentsString += attachments[index]
                            if (index < attachments.length - 1) {
                                attachmentsString += ', '
                            }
                        }
                    }

                    const projectData = projects.find(p => p.id == projectId)
                    let currency = ''
                    if (projectData && projectData.currency) {
                        currency = projectData.currency
                    }
                    let totalPrice = ''
                    if (projectData && projectData.price) {
                        totalPrice = projectData.price
                    }

                    let itemsString = ''
                    let code = ''
                    let qty = ''
                    let CO = ''
                    let supplier = ''
                    for (let i = 0; i < items.length; i++) {
                        if (items && items.length > 0) {
                            code = `${items[i].code}`
                            itemsString = `${items[i].description}`
                            qty = `${items[i].quantity}`
                            if (
                                items[i].purchaseOrder &&
                                items[i].purchaseOrder.orderNumber
                            ) {
                                CO = `${items[i].purchaseOrder.orderNumber}`
                            }
                            if (items[i].quotes && items[i].quotes.length > 0) {
                                const selectedQuote = items[i].quotes.find(
                                    q => q.able == true
                                )
                                if (selectedQuote) {
                                    supplier = selectedQuote.supplier.name
                                }
                            }
                        }

                        let unitPrice =
                            items[i].quotes && items[i].quotes.length > 0
                                ? items[i].quotes[0].price
                                : ''

                        row.id = number
                        row.project = project ? project.name : ''
                        row.projectManager = projectManager
                            ? projectManager.name
                            : ''
                        row.dueDate = dueDate
                            ? this.formatDate(dueDate._seconds)
                            : ''
                        row.deliveryLocation = deliveryLocation
                        row.order = code
                        row.items = itemsString
                        row.quantity = qty
                        row.unit = unitPrice
                        row.totalPriceItem = qty * unitPrice
                        row.CO = CO
                        row.supplier = supplier
                        row.type = type
                        row.projectTotalPrice = totalPrice
                        row.currency = currency
                        row.status = status
                        row.buyer = this.getUser(buyer)
                        row.createdBy = this.getUser(createdBy)
                        row.createdOn = createdOn
                            ? this.formatDate(createdOn._seconds)
                            : ''
                        row.rejectedDate = rejectedDate
                            ? this.formatDate(rejectedDate._seconds)
                            : ''
                        row.inProgressBy = this.getUser(inProgressBy)
                        row.inProgressDate = inProgressDate
                            ? this.formatDate(inProgressDate._seconds)
                            : ''
                        row.assignBy = this.getUser(assignBy)
                        row.assignDate = assignDate
                            ? this.formatDate(assignDate._seconds)
                            : ''
                        row.preApprovedBy = this.getUser(pricingBy)
                        row.preApprovedDate = pricingDate
                            ? this.formatDate(pricingDate._seconds)
                            : ''
                        row.approvedBy = this.getUser(preApprovedBy)
                        row.approvedDate = preApprovedDate
                            ? this.formatDate(preApprovedDate._seconds)
                            : ''
                        row.purchasedBy = this.getUser(approvedBy)
                        row.purchasedDate = approvedDate
                            ? this.formatDate(approvedDate._seconds)
                            : ''
                        row.inTransitBy = this.getUser(purchasedBy)
                        row.inTransitDate = purchasedDate
                            ? this.formatDate(purchasedDate._seconds)
                            : ''
                        row.receivedBy = this.getUser(inTransitBy)
                        row.receivedDate = inTransitDate
                            ? this.formatDate(inTransitDate._seconds)
                            : ''
                        row.vendorDate =
                            items[i].purchaseOrder &&
                            items[i].purchaseOrder.dueDate
                                ? this.formatDate2(
                                      items[i].purchaseOrder.dueDate._seconds
                                  )
                                : ''
                        row.closeBy = this.getUser(receivedBy)
                        row.closeDate = receivedDate
                            ? this.formatDate(receivedDate._seconds)
                            : ''
                        row.closedBy = this.getUser(closedBy)
                        row.closeDate = closedDate
                            ? this.formatDate(closedDate._seconds)
                            : ''
                        row.updatedOn = updatedOn
                            ? this.formatDate(updatedOn._seconds)
                            : ''
                        row.purchaseOrders = purchaseOrdersString
                        row.quotes = quotesString
                        row.attachments = attachmentsString
                        row = {
                            ...row,
                            ...params,
                        }
                        reportData.push(row)
                        itemsString = ''
                        row = {}
                    }
                }

                // only array possible
                const reportWS = XLSX.utils.json_to_sheet(reportData)
                // A workbook is the name given to an Excel file
                const wb = XLSX.utils.book_new() // make Workbook of Excel
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, reportWS, 'report') // sheetAName is name of Worksheet
                // export Excel file
                XLSX.writeFile(wb, `purchasesReport.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>
